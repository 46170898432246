export default () => {
  const shops = [
    {
      nickname: '萌宠之家',
      avatarUrl: require('./images/avatar2.jpeg').default,
      area: '四川 成都',
    },
    {
      nickname: '宠爱甄品舍',
      avatarUrl: require('./images/avatar1.jpg').default,
      area: '北京 东城',
    }
  ]

  function getShop() {
    return shops[Math.floor(Math.random() * shops.length)]
  }

  return [
    {
      name: '边境牧羊犬',
      price: 5000,
      shop: getShop(),
      pics: [
        require('./images/边境牧羊犬.png').default,
        // require('./images/边境牧羊犬/313D39F069FA03A609C58485F865619E.png').default,
        // require('./images/边境牧羊犬/D3DA2845F1BDFF8EFAF3140E56C9E8DB.png').default,
        // require('./images/边境牧羊犬/ECBDA19FD72285FD108C6299596CF075.png').default,
      ]
    },
    {
      name: '英短银点',
      price: 2500,
      shop: getShop(),
      pics: [
        require('./images/英短银点.png').default,
        // require('./images/英短银点/BA9095A56BB42D74C1E5E4D038183185.png').default,
        // require('./images/英短银点/F43A114EFE49E61AFF0518AADD6302DA.png').default,
        // require('./images/英短银点/F54C9283EFF4157DF714FA0709251E63.png').default,
      ]
    },
    {
      name: '英短起司',
      price: 3000,
      shop: getShop(),
      pics: [
        require('./images/美短起司.png').default,
        // require('./images/英短起司/1CD0C6B53779110657CA713DB25D2BE3.png').default,
        // require('./images/英短起司/1684A4EA5054F95DB705EC7E4A2DEEA7.png').default,
      ]
    },
    {
      name: '拉布拉多',
      price: 6900,
      shop: getShop(),
      pics: [
        require('./images/拉布拉多.png').default,
        // require('./images/拉布拉多/4A37D501FA0A3DEC07E1EA0412676E80.png').default,
        // require('./images/拉布拉多/4AB1E0865B2D52F696329C2DFBC5156D.png').default,
        // require('./images/拉布拉多/A52306A759A9221E29250195ACB4CF75.png').default,
      ]
    },
    {
      name: '布偶猫',
      price: 3600,
      shop: getShop(),
      pics: [
        require('./images/布偶猫.png').default,
        // require('./images/布偶猫/79E8C7846B9263FE1CA3A6E6576FA001.png').default,
        // require('./images/布偶猫/114771E7469CB19E8FAA73AAA0612416.png').default,
        // require('./images/布偶猫/FEF582D34DAC1FFB72E3766C22470390.png').default,
      ]
    },
    {
      name: '柯基犬',
      price: 3800,
      shop: getShop(),
      pics: [
        require('./images/柯基犬.png').default,
        // require('./images/柯基犬/C3E72F63CC9848A25069BA8086362CC8.png').default,
        // require('./images/柯基犬/C7A1120786C2C00441C547D9CD026F7F.png').default,
      ]
    },
    {
      name: '美国短毛猫',
      price: 2690,
      shop: getShop(),
      pics: [
        require('./images/美国短毛猫.png').default,
        // require('./images/美国短毛猫/ED1BF368EDA38A94CD4706BE3DB49DCB.png').default,
        // require('./images/美国短毛猫/FBE1ADF07DC10BA435770DA24733C880.png').default,
      ]
    },
    {
      name: '雪纳瑞',
      price: 4000,
      shop: getShop(),
      pics: [
        require('./images/雪纳瑞.png').default,
        // require('./images/雪纳瑞/011A5E051466E3CCF062F78D3C02A2AE.png').default,
        // require('./images/雪纳瑞/074CB403F9461F514352ADC9D1B129EC.png').default,
      ]
    }
  ]
}
